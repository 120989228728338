import { render, staticRenderFns } from "./ModuleCommerceTerm.vue?vue&type=template&id=9be80d7c&scoped=true&lang=pug&"
var script = {}
import style0 from "./ModuleCommerceTerm.vue?vue&type=style&index=0&id=9be80d7c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9be80d7c",
  null
  
)

export default component.exports