<template lang="pug">
  Auth
    div
      Header(:content="header")
    div.terms-wrapper.f.fc
      div.terms-container
        Commerce(v-if="type === 'commerce'")
        Service(v-if="type === 'service'")
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.terms-wrapper {
  width: 100%;
  height: 100vh;
  padding-top: 10%;
}

.terms-container {
  max-width: $base_max_width_px;
}
</style>

<script>
import Commerce from '@/components/module/terms/ModuleCommerceTerm'
import Service from '@/components/module/terms/ModuleServiceTerm'
import Header from '@/components/shared/Header'

export default {
  components: {
    Commerce,
    Service,
    Header
  },
  data () {
    return {
      type: null,
      header: {
        title: '規約',
        left: {
          icon: 'mdi-hand-wave',
          iconText: '募集',
          textColor: '#686868',
          to: '/create-order'
        },
        right: {
          icon: 'mdi-account-circle',
          to: '/mypage'
        }
      }
    }
  },
  created () {
    this.type = this.$route.params.type
  }
}
</script>
