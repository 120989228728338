<template lang="pug">
  //- アイコン部分はまたdivを作って、text-centerになるようにしたほうが流用的では？
  Auth
    div.wrap-shared-header.f.fc
      div.header-content.f.fh
        div(v-if="content.left" :style="`top: ${hasIconText(content.left.iconText)}px;`" @click="onLeft").left.f.fm
          v-icon(:color="content.left.color").mr2 {{content.left.icon}}
          span(:style="`color: ${content.left.textColor};`").icon-text.fz10.bold {{content.left.iconText}}
          span(:style="`color: ${content.left.color};`").fz14.mt2 {{content.left.label}}
        div(v-if="content.leftSecond" :style="`top: ${hasIconText(content.left.iconText)}px;`" @click="onLeftSecond").left-aside.f.fm
          v-icon(:color="content.leftSecond.color").mr2 {{content.leftSecond.icon}}
          span(:style="`color: ${content.leftSecond.textColor};`").icon-text.fz10.bold {{content.leftSecond.iconText}}
          span(:style="`color: ${content.leftSecond.color};`").fz14.mt2 {{content.leftSecond.label}}
        div.wrap-title
          span(v-if="content.title").line-clamp-1.fz14 {{content.title}}
          div.f.fh
            img.logo(v-if="content.image" :src="content.image").line-clamp-1.
        div(v-if="content.right" :style="`top: ${hasIconText(content.right.iconText)}px;`" @click="onRight").right.f.fm
          span(:style="`color: ${content.right.color};`").fz14.mr2.mt2 {{content.right.label}}
          span(:style="`color: ${content.right.textColor};`").icon-text.fz10.bold {{content.right.iconText}}
          v-icon(:color="content.right.color") {{content.right.icon}}
</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-shared-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 48px;
  background: $body_primary;
  border-bottom: solid $border_size $border_color;
  z-index: 1000;
  .header-content {
    position: relative;
    width: $base_width_percent;
    max-width: $base_max_width_px;
    height: 100%;
    // margin: 0 auto;
    .left {
      position: absolute;
      left: 0;
      height: 100%;
      color: $text_primary;
    }
    .icon-text {
      position: relative;
      top: 19px;
      left: -24px;
    }
    .left-aside {
      position: absolute;
      left: 40px;
      height: 100%;
      color: $text_primary;
    }
    .wrap-title {
      max-width: 160px;
      .logo {
        height: 32px;
      }
    }
    .right {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      color: $text_primary;
      color: #686868;

      .icon-text {
        position: relative;
        top: 19px;
        left: 36px;
      }
    }
  }
}
</style>

<script>
import Auth from '@/components/shared/Auth'

export default {
  components: {
    Auth
  },
  props: {
    content: {
      type: Object | null,
      required: true
    }
  },
  methods: {
    onLeft () {
      this.content.left.to && this.$router.push(this.content.left.to)
      this.content.left.action && this.content.left.action()
    },
    onLeftAside () {
      this.content.leftAside.to && this.$router.push(this.content.leftAside.to)
      this.content.leftAside.action && this.content.leftAside.action()
    },
    onRight () {
      this.content.right.to && this.$router.push(this.content.right.to)
      this.content.right.action && this.content.right.action()
    },
    hasIconText (text) {
      if (text) return -6
      else return 0
    }
  }
}
</script>
